
import apiUseCase from '@/usecase/apiUseCase'
import moment from 'moment'
import Card from 'primevue/card'
import QrCode from 'qrcode.vue'
import {
  defineComponent,
  onMounted,
  ref,
  watch
} from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { ITicket } from '@/utils/interfaces/iTicket'
import currencyNumberFormat from '@/utils/helpers/rupiahFormat'

export default defineComponent({
  name: 'VerbalReport',
  components: {
    Card,
    QrCode
  },
  setup() {
    const Qrvalue = ref('1') as any
    const Qrsize = ref(290) as any
    const route = useRoute() as any
    const ticketId = ref(route.params.id)
    const vehicleId = ref(route.params.vehicleId)
    const dataSource = ref<ITicket>()
    const isLoading = ref(true)
    const dateNow = moment().local()
    const date = moment(dateNow).format('DD-MM-YYYY')
    const time = moment(dateNow).format('HH:mm')
    const store = useStore()

    const endpoint = 'transaction/v1/Ticket/Scan-Ticket'

    const getData = async () => {
      try {
        isLoading.value = true
        const {
          error,
          result
        } = await apiUseCase.get(`${endpoint}?id=${ticketId.value}`)
        if (!error) {
          console.log('response', result)
          const resultMap: ITicket = result
          Qrvalue.value = resultMap.Id
          dataSource.value = resultMap
        }
        isLoading.value = false
      } catch (error) {
        isLoading.value = false
      }
    }

    watch(isLoading, (newVal) => {
      if (newVal) {
        store.dispatch('showLoading')
      } else {
        store.dispatch('hideLoading')
      }
    })

    onMounted(() => {
      getData()
    })

    return {
      Qrvalue,
      Qrsize,
      dataSource,
      isLoading,
      currencyNumberFormat,
      date,
      time
    }
  }
})

